export default {
	title: "theme", 
	colors: {
		primary: "#467dc0",
		blue: "#467dc0",
		ligthBlue: "#DAE5F2",
		paleBlue: "#90B1D9",
		orange: "#fbb22f",
		black: "#000000",
		dark: "#4E545C",
		white: "#ffffff",
		red: "#C31526",
		gray: "#6B7786",
		paleGray: "#CCCCCC",
		lightgray: "#E4E7EB",
		background: "#FFF",
		backgroundGray: "#F5F5F5",
	},
	fontSize: {
		smXX: "8px",
		smX: "10px",
		sm: "12px",
		md: "14px",
		base: "16px",
		lg: "18px",
		x: "20px",
		l: "24px",
		xl: "26px",
		xll: "28px",
		xxl: "32px",
		xxll: "48px",
	},
	shadow: {
		textShadow: "0px 20px 30px rgb(153 153 153 / 20%)",
	},
	transition: {
		transition: "all 300ms linear 0s", 
	},
	media: {
		monitor: "90rem",
		// 1440
		monitorSmall: "64rem",
		// 1024
		laptop: "56.25rem",
		// 900
		tablet: "48rem",
		// 768
		mobile: "37.5rem",
		// 600
		mobileSmall: "30rem", 
		// 480
		smWatch: "18.75rem"
		// 300
	}
};
