import { Container } from "./styles";
import { useState, useEffect } from "react";
import axios from "axios";
import { Cause } from "../../../interfaces/interfaces";


export const ListCardsCause = () => {
	const [causes, setCauses] = useState<Cause[]>([]);

	useEffect(() => {
		axios
			.get("https://dev.ifdot.com.br/campaigns")
			.then((response) => {
				setCauses(response.data);
			})
			.catch((error) => console.log(error));
	}, [setCauses]);
	console.log(causes)

	return (
		<Container.Box>
			<Container.Cards>
				<Container.Card>
					{/* {causes.length > 0 ? (
						causes.map((cause: Cause) => (
							<Campaign
								cause={cause}
								key={cause.id}
							/>
						))
					) : (
						<p>{causes.length === 0 ? '' : 'Loading...'}</p>
					)} */}
				</Container.Card>
			</Container.Cards>
		</Container.Box>
	);
};
