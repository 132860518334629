import styled from 'styled-components';

export const Container = styled.section`
    padding: 0px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #467DC0;
    text-align: center;
    h2 {
        padding-bottom: 40px;
        @media (max-width: 480px) {
        font-weight: 700;
        font-size: 48px;
        line-height: 52px;
      }
      span {
        text-decoration:  underline 3px solid #FBB22F;;
      }
    }
`;

export const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    @media (max-width: 1024px) {
        width: 90%;
    }
`;

export const Cards = styled.div`
    min-width: 20%;
    height: 220px;
    padding: 35px;
    margin: 0px 15px 30px;
    box-shadow: 0px 2px 10px rgba(73, 80, 87, 0.16);
    border-radius: 8px;
    text-align: center;
    transition: all 300ms linear 0s;
    background: #f9fafc;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
        max-width: 200px;
        @media (max-width: 375px) {
          max-width: 150px;
    }
    };
    &:hover {
        box-shadow: 0px 20px 30px rgb(153 153 153 / 20%);
    }
    @media (max-width: 1024px) {
        min-width: 170px;
    }
    @media (max-width: 480px) {
        max-width: 100%;
    }
`;

export const Button = styled.button`
background: #467DC0;
box-shadow: 0px 2px 12px rgba(73, 80, 87, 0.24);
border-radius: 4px;
font-weight: 500;
font-size: 16px;
line-height: 24px;
color: #FFFFFF;
padding: 4px 50px;
cursor: pointer;
border: none;
`;
