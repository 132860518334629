
import styled from "styled-components";

const Container__Box = styled.div``;


const Container__Cards = styled.div`
    padding: 30px 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-direction: column;
`;

const Container__Card = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;
`;

export const Container = {
	Box: Container__Box,
	Cards: Container__Cards,
	Card: Container__Card,
};


export const Pagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

interface PageButtonProps {
  active?: boolean;
}

export const PageButton = styled.button<PageButtonProps>`
  padding: 10px;
  margin: 0 5px;
  background-color: ${({ active }) => (active ? "gray" : "white")};
  color: ${({ active }) => (active ? "white" : "black")};
  border: 1px solid gray;
  border-radius: 5px;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  &:hover {
    background-color: ${({ active }) => (active ? "gray" : "#e6e6e6")};
  }
`;

