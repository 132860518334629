import styled from "styled-components";
import sliders from "../../../../assets/slider/sliders.svg";

interface SubTitleProps extends React.HTMLProps<HTMLHeadingElement> {
  isSecondSlide?: boolean;
}

interface ButtonProps {
  left?: boolean;
}

const Container__main = styled.main`
    height: 700px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${sliders});
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
`;

const Container__Text = styled.div`
  padding: 0 100px;
  display: flex;
  color: white;
  flex-direction: column;
  justify-content: center;
  z-index: 0;
  height: 100%;
  @media (max-width: 480px) {
    padding: 0 40px;
    }
`;


export const Container = {
	Main: Container__main,
	Text: Container__Text,
};

const Text__Title = styled.h1`
font-weight: 600;
font-size: 32px;
line-height: 36px;
padding-bottom: 8px;
  @media (max-width: 480px) {
        font-size: 25px;
    }
`;
const Text_SubTitle = styled.h2<SubTitleProps>`
background: ${(props) => (props.isSecondSlide ? "#467DC0" : "#FBB22F")};
  border-radius: 8px;
  font-weight: 700;
  font-size: 48px;
  line-height: 52px;
  padding: 8px;
  margin-bottom: 16px;
  width: 368px;
  @media (max-width:  768px) {
    width: 280px;
    font-size: 36px;
    }
  @media (max-width:  375px) {
  width: 220px;
  font-size: 28px;
  }
`;

const Text__Desc = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  width: 40%;
  padding-bottom: 16px;
  @media (max-width: 768px) {
    width: 100%;
    }
`;



export const Text = {
	Title: Text__Title,
	SubTitle: Text_SubTitle,
	Desc: Text__Desc,
};

const Button__White = styled.button`
background: #FFFFFF;
box-shadow: 0px 2px 12px rgba(73, 80, 87, 0.24);
border-radius: 4px;
padding: 4px 8px;
font-weight: 500;
font-size: 16px;
line-height: 24px;
color: #467DC0;
border: none;
width: 162px;
cursor: pointer;
font-family: 'Poppins';
`;

const Button__Arrow = styled.button<ButtonProps>`
position: absolute;
  top: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 8px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.5);

  ${(props) => (props.left ? "left: 10px;" : "right: 10px;")}
`;


export const Button = {
	White: Button__White,
	Arrow: Button__Arrow,
};