import styled from "styled-components";
import theme from "../../../../themes/theme";

const Image__Campaign = styled.div`
  width: 50%;
  height: 100%;
  padding: 16px;
  background: #FFF;
  box-shadow: 0px 2px 10px rgba(73, 80, 87, 0.16);
  border-radius: 16px;
  @media (max-width:${theme.media.monitorSmall}){
    width: 100%;
  } 
`;

const Image__Cards = styled.div`
  img{
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    height: 400px;
    object-fit: cover;
    border-radius: 8px;
  @media (max-width:${theme.media.monitorSmall}) {
    height: 300px;
    }
  @media (max-width:${theme.media.smWatch}) {
    height: 200px;
  }
  }
`;

const Image__Small = styled.div`
  display: flex;
  width: 100%;
  min-height: 20%;
  justify-content: space-between;
  object-fit: cover;
  padding-top: 4px;
  img {
    object-fit: cover;
    width: 24%;
    border-radius: 8px;
  }
`;

export const Image = {
	Campaign: Image__Campaign,
	Cards: Image__Cards,
	Small: Image__Small,
};

