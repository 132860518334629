import { Container, Wrapper, Cards, Button } from './styles';
import { partnerCard } from '../../../../data';
import { useState } from 'react';
import { ModalForm } from '../ModalForm';

const Partner = () => {

    const [openModal, setOpenModal] = useState(false);

    return (
        <Container>
            <h2>Marcas que <span>fazem o bem</span></h2>
            <Wrapper>
                {partnerCard.map((item) => (
                    <Cards key={item.id}>
                        <img src={item.img} alt="logo" />
                    </Cards>
                ))}
            </Wrapper>
            <Button onClick={() => setOpenModal(true)}>Seja um parceiro!</Button>
            <ModalForm open={openModal} onClose={() => setOpenModal(false)}/>

        </Container>
    )
}

export default Partner