import { Container, Text } from "./styles";
import woman from "../../../assets/woman.svg";
import esg from "../../../assets/esg.svg";
import { Card } from "../Card";


export const Feature = () => {

	return (
		<Container.Feature id="gooders">
			<Text.Title>A <span>Gooders</span></Text.Title>
			<Card />
			<Container.Box>
				<Container.Text>
					<Text.Desc>Somos uma plataforma de engajamento social, com recompensas reais que fomenta negócios de impacto. Nossa missão é motivar a resolução de problemas sociais, recompensar as pessoas que fazem o bem e motivar o crescimento do pensamento em larga escala!</Text.Desc>
				</Container.Text>
				<Container.Icon style={{ justifyContent: "flex-start" }}> <img src={woman} alt="" /></Container.Icon>
			</Container.Box>
			<Text.Title>Somos <span>ESG</span></Text.Title>
			<Container.Box>
				<Container.Icon> <img src={esg} alt="" /></Container.Icon>
				<Container.Text>
					<Text.Desc>A Gooders é uma empresa comprometida em promover e integrar práticas de ESG em todas as suas operações, incluindo a minimização do impacto ambiental das atividades e produtos, a adoção de práticas justas e equitativas em todas as relações, o apoio a causas sociais e ambientais, a transparência e ética em todas as relações comerciais e financeiras e uma governança corporativa sólida e democrática. Acreditamos que essa abordagem é essencial para um futuro sustentável e estamos comprometidos em ser uma empresa ESG.</Text.Desc>
				</Container.Text>
			</Container.Box>

		</Container.Feature>
	);
};
