import { Container, Text, CloseIcon } from "./styles";
import { useFormik } from "formik";
import formpartner from "../../../../assets/formpartner.svg"
import logo_blue from "../../../../assets/logo/logo_blue.svg"
import close from "../../../../assets/icones/close.svg"

interface ModalFormProps {
    open: boolean;
    onClose: () => void;
}

const validate = (values: { name: string, email: string, telephone: string, message: string }) => {
    const errors: { name?: string, email?: string, telephone?: string, message?: string } = {};
    if (!values.name) {
        errors.name = "Preencha o seu nome";
    } else if (values.name.length > 20) {
    }
    if (!values.email) {
        errors.email = "Insira o seu email";
    }
    if (!values.message) {
        errors.message = "Insira o seu número de telefone";
    }
    if (!values.message) {
        errors.message = "Preencha com a sua mensagem";
    }
    return errors;
};
export const ModalForm = ({ open, onClose }: ModalFormProps) => {

    if (!open) return null;

    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            telephone: "",
            message: "",
        },
        validate,
        onSubmit: async (values) => {

        }
    });


    return (
        <Container.box >
            <Container.modal>
            <CloseIcon src={close} alt="close" onClick={onClose} />
                <Container.img>
                    <img src={formpartner} alt="" />
                </Container.img>
                <Container.auth>
                    <Container.header>
                        <img src={logo_blue} alt="" />
                        <Text.title>Seja nosso parceiro!</Text.title>
                    </Container.header>
                    <Container.form onSubmit={formik.handleSubmit}>
                        <label htmlFor="name">
                            <span>Nome</span>
                            <input
                                id="name"
                                name="name"
                                type="text"
                                placeholder="Insira seu nome"
                                onChange={formik.handleChange}
                                value={formik.values.name}
                                aria-labelledby="name"
                            />
                        </label>
                        {formik.errors.name ? <div className="error">{formik.errors.name}</div> : null}
                        <label htmlFor="email">
                            <span>Email</span>
                            <input
                                id="email"
                                name="email"
                                type="text"
                                placeholder="Insira seu email"
                                onChange={formik.handleChange}
                                value={formik.values.email}
                                aria-labelledby="email"
                            />
                        </label>
                        {formik.errors.email ? <div className="error">{formik.errors.email}</div> : null}
                        <label htmlFor="telephone">
                            <span>Telefone</span>
                            <input
                                id="telephone"
                                name="telephone"
                                type="text"
                                placeholder="Insira seu telephone"
                                onChange={formik.handleChange}
                                value={formik.values.telephone}
                                aria-labelledby="telephone"
                            />
                        </label>
                        {formik.errors.telephone ? <div className="error">{formik.errors.telephone}</div> : null}
                        <label htmlFor="message">
                            <span>Mensagem</span>
                            <textarea
                                id="message"
                                name="message"
                                rows={3}
                                placeholder="Insira seu texto"
                                onChange={formik.handleChange}
                                value={formik.values.message}
                                aria-labelledby="message"
                            />
                        </label>
                        {formik.errors.message ? <div className="error">{formik.errors.message}</div> : null}
                        <Container.button type="submit"
                            onClick={formik.submitForm}
                            aria-label="Enviar">
                            Enviar
                        </Container.button>
                    </Container.form>


                </Container.auth>
            </Container.modal>
        </Container.box>
    );
};
