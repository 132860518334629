import styled from "styled-components";



const Container__Box = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(70, 125, 192, 0.20);
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  color: black;
  z-index: 999;
  .error {
    color: #C31526;
    font-weight: 400;
    font-size: 10px;
    line-height: 18px;
  }
`;

const Container__Modal = styled.div`
position: relative;
  width: 70%;
  background: #FFFFFF;
  display: flex;
  gap: 20px;
  box-shadow: 0px 2px 14px rgba(73, 80, 87, 0.2);
  border-radius: 8px;
  padding: 24px;
  @media (max-width: 768px) {
    flex-direction: column;
}
@media (max-width: 480px) {
  width: 100%;
  height: 100%;
  justify-content: space-around;
}

`;

const Container__Img = styled.div`
  width: 60%;
  img{ 
    min-width: 100%;
    min-height: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
  @media (max-width: 768px) {
    display: none;
}
`;

const Container__Auth = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  span {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #4E545C;
  padding: 16px;
  }
  @media (max-width: 768px) {
    width: 100%;
}
`;

const Container__Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  img {
    width: 244px;
    @media (max-width: 480px) {
    width: 90%;
}
  }
`;


const Container__Form = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    label {
      position: relative;
        margin-top: 20px;
        border-radius: 8px;
        border: 1px solid #4E545C;
        padding: 6px 8px;
    }
    input {
        width: 100%;
        border-radius: 8px;
        border: none;
        outline: none;
        background: transparent;
    }
    span {
      padding: 2px;
      background: #FFF;
      position: absolute;
      top: -10px;
      left: 20px;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: #4E545C;
    }
    textarea {
      width: 100%;
      outline: none;
      border: none;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      padding: 6px 0;
      font-family: 'Poppins';
    }
    
`;

const Container__Button = styled.button`
width: 100%;
background: #467DC0;
padding: 18px 0;
border-radius: 4px;
font-weight: 500;
font-size: 16px;
line-height: 24px;
margin-top: 22px;
color: #FFFFFF;
border: none;
cursor: pointer;
`;


export const Container = {
	box: Container__Box,
	modal: Container__Modal,
  img: Container__Img,
	auth: Container__Auth,
	header: Container__Header,
	form: Container__Form,
  button: Container__Button,
};

const Text__Title = styled.h1`
font-weight: 700;
font-size: 28px;
line-height: 32px;
color: #467DC0;
@media (max-width: 480px) {
  font-size: 24px;
}
`;

export const Text = {
	title: Text__Title,
};

export const CloseIcon = styled.img`
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    
  `;
