
import { RouterProvider } from "./components/config/routes";

const App: React.FC = () => {


	return (
		<>
			<RouterProvider />
		</>
	);
};

export default App;
