import React from "react";
import { Container } from "./styles";
import globe from "../../../assets/icones/globe.svg"
import { Link } from "react-router-dom";

interface RightNavProps {
	open: boolean;
}

const RightNav: React.FC<RightNavProps> = ({ open }) => {


	return (

		<Container.Nav open={open} >
			<Container.Right open={open} >
				<ul>
					<a href="/"><li>Gooders</li></a>
					<Link to="/campanhas"><li>Causas</li></Link>
					<a href="/#recompensas"><li>Recompensas</li></a>
					{/* <li>Contato</li> */}
					<Link to="https://app.gooders.com.br/login"><li>Acessar</li></Link>

					<li><img src={globe} alt="globe" />PT/BR</li>
				</ul>
			</Container.Right>

		</Container.Nav>
	);
};



export default RightNav;
