import { Container, Text, Button } from "./styles";
import sliders from "../../../../assets/slider/sliders.svg";
import slider_campaign from "../../../../assets/slider/slider_campaign.svg"
import arrow_left from "../../../../assets/icones/arrow_left.svg"
import arrow_right from "../../../../assets/icones/arrow_right.svg"
import { useEffect, useState } from "react";


export const sliderHome = [
	{
		title: "FAZER O BEM",
		subtitle: "RECOMPENSA",
		description: "Receba benefícios junto aos nossos parceiros fazendo o bem e gerando um bom impacto social!",
		urls: sliders,
	},
	{
		title: "Fazer o bem",
		subtitle: "RECOMPENSA",
		description: "Receba benefícios junto aos nossos parceiros fazendo o bem e gerando um bom impacto social!",
		urls: slider_campaign,
	},

]

export const Slider = () => {

	const [currentSlide, setCurrentSlide] = useState(0);

	const handlePrevSlide = () => {
		setCurrentSlide(currentSlide === 0 ? sliderHome.length - 1 : currentSlide - 1);
	};

	const handleNextSlide = () => {
		setCurrentSlide(currentSlide === sliderHome.length - 1 ? 0 : currentSlide + 1);
	};

	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentSlide((currentSlide) =>
				currentSlide === sliderHome.length - 1 ? 0 : currentSlide + 1
			);
		}, 5000);

		return () => clearInterval(interval);
	}, []);

	return (
		<Container.Main style={{ backgroundImage: `url(${sliderHome[currentSlide].urls})` }}>
			<Container.Text>
				<Text.Title>{sliderHome[currentSlide].title}</Text.Title>
				<Text.SubTitle isSecondSlide={currentSlide === 1}>{sliderHome[currentSlide].subtitle}</Text.SubTitle>
				<Text.Desc>{sliderHome[currentSlide].description}</Text.Desc>
				<a href="/#recompensas"><Button.White>Saiba Mais</Button.White></a>
			</Container.Text>

			<Button.Arrow left onClick={handlePrevSlide}>
				<img src={arrow_left} alt="Previous slide" />
			</Button.Arrow>
			<Button.Arrow onClick={handleNextSlide}>
				<img src={arrow_right} alt="Next slide" />
			</Button.Arrow>

		</Container.Main>


	);
};


