import styled from "styled-components";


const Container__Input = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-width: 100%;
    min-height: 100%;
    gap: 10px;
    padding: 40px 16px;
    color: #6B7786;
    @media (max-width: 900px) {
      flex-direction: column;
    }
    
`;

const Container__Text = styled.div`
    display: flex;
    justify-content: flex-start;
    width: 50%;
    min-height: 250px;
    flex-direction: column;
    gap: 30px;    
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    @media (max-width: 900px) {
      width: 100%;;
    }
`;
const Container__Image = styled.div`
    width: 50%;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    img {
      min-width: 100%;
        max-width: 100%;
        max-height: 100%;
        min-height: 100%;
        object-fit: cover;
        border-radius: 8px;
    }
    @media (max-width: 900px) {
      width: 100%;;
    }
`;


export const Container = {
	Input: Container__Input,
	Text: Container__Text,
	Image: Container__Image,
};




const Input__donate = styled.div`
    input {
        width: 100%;
        padding: 15px;
        border: 1px solid #E4E7EB;
        border-radius: 4px;
        background: white;
        outline: none;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
    }
`;

export const Input = {
	Donate: Input__donate,
};

export const Button = styled.button`
    padding: 10px;
    width: 95%;
    color: white;
    background: #467DC0;
    border-radius: 4px;
    margin-bottom: 20px;
    border: none; 
    cursor: pointer;
    transition: all 300ms linear 0s;
    &:hover {
      background: #6B97CD;
    }
`;




