import styled from "styled-components";
import map from "../../../../assets/map.png";


const Container__Banner = styled.section`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    height: 100vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${map});
    text-align: center;
    @media (max-width: 1024px) {
      height: auto;
      }
    `;

const Container__Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  padding: 56px;
  box-shadow: 0px 2px 10px rgba(73, 80, 87, 0.16);
  border-radius: 16px;
  background: white;
  z-index: 2;
  @media (max-width: 480px) {
    padding: 56px 10px;
    width: 100%;
      }
  
`;

const Container__Text = styled.div`
display: flex;
gap: 50px;

iframe {
  flex: 1;
  min-width: 50%;
  min-height: 280px;
  border-radius: 20px;
  @media (max-width: 900px) {
    width: auto;
    max-width: 100%;
  }
}
@media (max-width: 900px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      }
`;

export const Container = {
	Banner: Container__Banner,
	Box: Container__Box,
	Text: Container__Text,
};

const Text__Title = styled.h3`
    h2 {
        padding-bottom: 40px;
        color: #467DC0;
        font-weight: 700;
        font-size: 48px;
        line-height: 52px;
      span {
        text-decoration:  underline 3px solid #FBB22F;;
      }
      @media (max-width: 768px) {
        font-size: 32px;
      }
      @media (max-width: 480px) {
        font-size: 22px;
      }
    }
`;

const Text__desc = styled.p`
flex: 1;
font-weight: 400;
font-size: 16px;
line-height: 24px;
text-align: start;
color: #4E545C;
display: flex;
flex-direction: column;
gap: 22px;
width: 100%;
`;

export const Text = {
	Title: Text__Title,
	Desc: Text__desc,
	
};


export const Button = styled.button`
background: #467DC0;
box-shadow: 0px 2px 12px rgba(73, 80, 87, 0.24);
border-radius: 4px;
font-weight: 500;
font-size: 16px;
line-height: 24px;
color: #FFFFFF;
padding: 4px 50px;
cursor: pointer;
border: none;
width: 258px;
@media (max-width: 480px) {
  width: 100%;
      }
`;
