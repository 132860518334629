import styled from "styled-components";
import slider_campaign from "../../../assets/slider/slider_campaign.svg";

const Container__main = styled.main`
    height: 449px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${slider_campaign});
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Container__Text = styled.div`
  display: flex;
  color: white;
  justify-content: center;
  z-index: 0;
`;

export const Container = {
	Main: Container__main,
	Text: Container__Text
};

const Text__Title = styled.h1`
  font-weight: 600;
  font-size: 48px;
  line-height: 52px;
  @media (max-width: 900px) {
    font-size: 36px;
    }
    @media (max-width: 480px) {
    font-size: 24px;
    }
    @media (max-width: 375px) {
    display: none;
    }
`;


export const Text = {
	Title: Text__Title,
};