import styled from 'styled-components';

export const Container = styled.section`
    padding: 20px ;
    color: gray;
    @media (max-width: 600px) {
        padding: 40px 20px;
    }
`;

export const Wrapper = styled.div`
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    gap: 30px;
    @media (max-width: 769px) {
        flex-wrap: wrap;
        gap: 15px;
    }
`;

export const Cards = styled.div`
    min-width: 33.33%;
    height: 300px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    margin-bottom: 30px;
    transition: all 300ms linear 0s;
    background: #f9fafc;
    box-shadow: 0px 2px 10px rgba(73, 80, 87, 0.16);
    border-radius: 8px;
    h4 {
        font-size: 18px;
        margin-bottom: 8px;
        color: #fbb22f ;
    };
    p {
      font-size: 14px;
    }
    img {
        margin-bottom: 8px;
        max-width: 100px;
    };
    &:hover {
        box-shadow: 0px 20px 30px rgb(153 153 153 / 20%);
    }
    @media (max-width: 1024px) {
        min-width:200px;
        padding: 20px;
        
    }
    @media (max-width: 768px) {
        max-width: 80%;
        padding: 16px;
    }
`;
