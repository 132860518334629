import { Container, Button, Input, Line } from "./styles";
import { useState } from "react";
import { UniqueCampaignOutput } from "../../../../../../interfaces/interfaces";

interface Props {
  selectedCampaign?: UniqueCampaignOutput;
}

export const ModalVoluntary: React.FC<Props> = ({ selectedCampaign }) => {
  const [hours, setHours] = useState("");
  const [service, setService] = useState("");
  const [selectedItem, setSelectedItem] = useState<{
    address: string;
    state: string;
    city: string;
    startDate: string;
    endDate: string;
    description: string;
  } | null>(null);



  const handleDonationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHours(e.target.value);
  };

  const handleQuantityChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedItems = selectedCampaign?.vacancies?.find(
      (item) => item.title === e.target.value
    );
    setService(e.target.value);
    if (selectedItems) {
      setSelectedItem({
        address: selectedItems.address,
        city: selectedItems.city,
        state: selectedItems.state,
        startDate: selectedItems.startDate,
        endDate: selectedItems.endDate,
        description: selectedItems.description,
      });
    }
  };

  const handleDonateClick = () => {
    window.open('https://app.gooders.com.br/login', '_blank');
  };

  return (
    <>
      <Container.Input>
        <Container.Text>
          <Input.Donate>
            <label htmlFor="service" id="service"></label>
            <select
              name="service"
              id="service"
              value={service}
              onChange={handleQuantityChange}
              required
            >
              <option value="">Vagas</option>
              {selectedCampaign &&
                selectedCampaign.vacancies.map((item) => (
                  <option key={item.id} value={item.title}>
                    {item.title}
                  </option>
                ))}
            </select>
          </Input.Donate>
          <Input.Hours>
            <label htmlFor="hour">
              <input
                type="text"
                placeholder="Quantidade de horas"
                value={hours}
                onChange={handleDonationChange}
                required
              />
            </label>
          </Input.Hours>
          {selectedItem && (
            <Container.Address>
              {selectedItem.description},
              <Line className="line" />
              {selectedItem.address},{selectedItem.city},{selectedItem.state},
              <Line className="line" />
              {selectedItem.startDate} - {selectedItem.endDate}
            </Container.Address>
          )}
        </Container.Text>
      </Container.Input>
      <Button onClick={handleDonateClick}>Finalizar Doação</Button>
    </>
  );
};
