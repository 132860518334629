import styled from "styled-components";
import theme from "../../../../themes/theme";

const Container__Box = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: space-between;
  @media (max-width:${theme.media.tablet}) {
      flex-direction: column;
      align-items: flex-start;
    } 
`;

const Container__Value = styled.div`
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #4E545C;
  h4 {
    font-size: 20px;
    line-height: 32px;
  }
  h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 44px;
  }
`;

export const Container = {
	Value: Container__Value,
	Box: Container__Box,
};


const Description__SubTitle = styled.div` 
  font-weight: 500;
  line-height: 24px;
  color: #6B7786;
  p {
    color: #6B7786;
    padding-bottom: 8px;
  }
`;

const Description__Text = styled.div` 
  font-size: 24px;
  line-height: 32px;
  color:#4E545C;
`;


export const Description = {
	Subtitle: Description__SubTitle,
	Text: Description__Text,

};