

export const partnerCard = [
  {
      id: 1,
      img: "https://i.imgur.com/fWsGwbP.png",
      title: "MC Donalds",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
  {
      id: 2,
      img: "https://i.imgur.com/NnjeGu3.png",
      title: "Extra",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
  {
      id: 3,
      img: "https://i.imgur.com/tBZoytn.png",
      title: "Casas Bahia",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
  {
      id: 4,
      img: "https://i.imgur.com/T50ywAd.png",
      title: "Reserva",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
  {
      id: 5,
      img: "https://i.imgur.com/E9zghWz.png",
      title: "Telha Norte",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
  {
      id: 6,
      img: "https://i.imgur.com/G5Dwdw2.png",
      title: "Giuliana Flores",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
  {
      id: 7,
      img: "https://i.imgur.com/TBnPgPE.png",
      title: "IPlace",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
  {
      id: 8,
      img: "https://i.imgur.com/uOdEMFk.png",
      title: "Magalu",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
  {
      id: 9,
      img: "https://i.imgur.com/rfUZGWO.png",
      title: "NetShoes",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
  {
      id: 10,
      img: "https://i.imgur.com/A5q5NXx.png",
      title: "Ponto",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
  },
];

