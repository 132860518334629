import styled from "styled-components";



export const Container = styled.button`
    display: inline-block;
    width: 100%;
    padding: 12px 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    transition: all 300ms linear 0s;
    background: #467DC0;
    color: #fff;
    border: none;
    &:hover {
        background: #6B97CD; 
    };
    `;

export const _BlueButton = styled(Container)`
`;

export const _SmallBlueButton = styled(Container)`
padding: 4px 8px;
width: 100%;
`;


export const _SmallTransparentBlueButton = styled(_SmallBlueButton)`
border: solid 1px #467DC0 !important;
background: #fff;
color: #467DC0;
width: 100%;
&:hover {
        color: #fff; 
    };
`;

export const _RedButton = styled(Container)`
  background: #C31526;
  &:hover {
        background: #CF4451; 
    };
`;

export const _SmallRedButton = styled(_RedButton)`
  padding: 4px 8px;
`;

export const _GreenButton = styled(Container)`
  background: #01742D;
  &:hover {
        background: #349057; 
    };
  
`;

export const _SmallGreenButton = styled(Container)`
padding: 4px 8px;
width: auto;
background: #01742D;
  &:hover {
        background: #349057; 
    };
`;

