
import { Container, Title } from "./styles";
import { Link } from "react-router-dom";
import { ModalDonation } from "../Modal/ModalDonation";
import { ModalPayment } from "../Modal/ModalPayment";
import { ModalVoluntary } from "../Modal/ModalVoluntary";
import { useEffect, useState } from "react";
import { UniqueCampaignOutput } from "../../../../../interfaces/interfaces";
import { axiosClient } from "../../../../config/axios";



interface Props {
	open: boolean;
	onClose: () => void;
	selectedCause: UniqueCampaignOutput;
}

export const LayoutModal = ({ open, onClose, selectedCause }: Props) => {

	const [activeTab, setActiveTab] = useState("payment");
	const [selectedCampaign, setSelectedCampaign] = useState<UniqueCampaignOutput>({
		id: "",
		title: "",
		summary: "",
		description: "",
		category: "",
		image: "",
		address: "",
		state: "",
		city: "",
		isActive: false,
		createdAt: "",
		updatedAt: "",
		responsible: "",
		responsibleTelephone: "",
		institution: {
			id: "",
			name: "",
		},
		images: [],
		items: [],
		moneyDonationOptions: null,
		vacancies: [],
	});


	useEffect(() => {
		const fetchCauses = async () => {
			try {
				const response = await axiosClient.get<{ data: UniqueCampaignOutput }>(`https://dev.ifdot.com.br/campaigns/unique/${selectedCause.id}`);
				const data = response.data;
				setSelectedCampaign(data.data);
			} catch (error) {
				console.error(error);
			}
			window.scrollTo(0, 0);
		};
		fetchCauses();
	}, []);

	if (!open) return null;

	const handleTabClick = (tabName: string) => {
		setActiveTab(tabName);
	};


	return (
		<Container.Main onClick={onClose}>
			<Container.Modal onClick={(e) => { e.stopPropagation(); }}>
				<Container.Title>
					<Link to="#" onClick={() => handleTabClick("payment")}>
						<Title.Donate active={activeTab === "payment"} >Dinheiro</Title.Donate>
					</Link>
					<Link to="#" onClick={() => handleTabClick("donation")}>
						<Title.Pay active={activeTab === "donation"} >Doação</Title.Pay>
					</Link>
					<Link to="#" onClick={() => handleTabClick("voluntary")}>
						<Title.Voluntary active={activeTab === "voluntary"}>Voluntariado</Title.Voluntary>
					</Link>
				</Container.Title>
				<Container.Content>
					<Container.Border>
						{activeTab === "donation" && <ModalDonation selectedCampaign={selectedCampaign} />}
						{activeTab === "voluntary" && <ModalVoluntary selectedCampaign={selectedCampaign} />}
						{activeTab === "payment" && <ModalPayment selectedCampaign={selectedCampaign} />}
					</Container.Border>
				</Container.Content>
			</Container.Modal>
		</Container.Main>
	);
};