import { Container, Description } from "./styles";
import locality from "../../../../assets/icones/locality.svg";
import { UniqueCampaignOutput } from "../../../../interfaces/interfaces";

interface Props {
	selectedCause: UniqueCampaignOutput;
}


export const HeaderCampaign: React.FC<Props> = ({ selectedCause }) => {

	return (
		<>
			<Description.Title>{selectedCause?.title}</Description.Title>
			<Description.Subtitle style={{ minHeight: "210px" }}>
				<p>{selectedCause?.description}</p>
			</Description.Subtitle>

			<Container.Location>
				<img src={locality} alt="cidade" />
				<span>{selectedCause?.city} - </span>
				<span>{selectedCause?.state}</span>
			</Container.Location>
		</>
	);
};
