
import { Container } from "./styles";
import logo_white from "../../../assets/logo/logo_white.svg";
import Burger from "../Burguer";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";


export const Navbar = () => {

	const [isScrolled, setIsScrolled] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			const scrollTop = window.pageYOffset;
			setIsScrolled(scrollTop > 0.1 * window.innerHeight);
		};

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (
		<Container.Header isScrolled={isScrolled}>
			<Container.Box>
				<Link to="/"><Container.Image src={logo_white} alt="logo" /></Link>
				<Container.Nav> 
					<Burger /> 
				</Container.Nav> 
			</Container.Box>
		</Container.Header>
	);
};
