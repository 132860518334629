import styled from "styled-components";

interface Props {
  active?: boolean;
}

const Container__Main = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    background: rgba(6, 53, 122, 0.05);
    top: 0px;
    left: 0px;
    @media (max-width: 900px) {
      padding: 30px;
      height: auto;
    }
`;


const Container__Modal = styled.div`
    min-width: 60%;
    max-width: 60%;
    background: rgba(0, 0, 0, 0);
    transition: all 300ms linear 0s;
    box-shadow: 0px 20px 30px rgb(153 153 153 / 40%);
    @media (max-width: 480px) {
      max-width: 100%;
    }
`;

const Container__title = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
    background: transparent;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #FFFFFF;
`;

const Container__Content = styled.div`
    position: relative;
    background: #FFF;
    border-radius: 0px 8px 8px 8px;
    min-height: 100%;

`;



const Container__Border = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
`;
export const Container = {
	Main: Container__Main,
	Modal: Container__Modal,
	Content: Container__Content,
	Title: Container__title,
	Border: Container__Border,
};


const Title__Pay = styled.div<Props>`
  padding: 8px;
  background:  ${props => props.active ? '#6B97CD' : '#DAE5F2'};
  border-radius: 8px 8px 0px 0px;
  color: ${props => props.active ? '#FFFFFF' : '#90B1D9'};`;


const Title__Donate = styled(Title__Pay)`
`;
const Title__Voluntary = styled(Title__Pay)``;

export const Title = {
	Pay: Title__Pay,
	Donate: Title__Donate,
	Voluntary: Title__Voluntary,
};
