import { useEffect, useState } from 'react';
import { Container } from './styles';
import { useSearch } from '../../components/hooks/useSearch';
import { SearchBar } from './components/Search/index';
import { PaginationListCause } from './components/PaginationListCause';
import { Campaign } from '../../components/common/CardsCause';
import { CampaignArrayOutput } from '../../interfaces/interfaces';
import { CategorieIcons } from './components/CategorieIcons';
import { Navbar } from '../../components/common/Navbar';
import { SliderCampaign } from '../../components/common/SliderCampaing';
import { axiosClient } from '../../components/config/axios';
import Footer from '../../components/common/Footer';

const pageSizeOptions = [4, 8, 16, 32];

export const ListCampaing = () => {
	const [causes, setCauses] = useState<CampaignArrayOutput[]>([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(pageSizeOptions[0]);
	const [selectedCategory, setSelectedCategory] = useState('');
	const { searchTerm, handleSearchTermChange } = useSearch({
		onSearchTermChange: (term) => console.log(term),
	});

	useEffect(() => {
		const fetchCauses = async () => {
			try {
				const response = await axiosClient.get<{ data: CampaignArrayOutput[] }>('https://app.gooders.com.br/campaigns');
				const data = response.data;

				setCauses(data.data);

				console.log("data", data)
			} catch (error) {
				console.error(error);
			}
		};
		fetchCauses();
	}, []);

	console.log("causas", causes)


	const handlePageSizeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		setPageSize(Number(event.target.value));
		setCurrentPage(1);
	};

	const filteredCauses = Array.isArray(causes)
		? causes
			.filter((cause: CampaignArrayOutput) =>
				searchTerm
					? [cause.title?.toLowerCase(), cause.description?.toLowerCase()].some((field) =>
						field?.includes(searchTerm.toLowerCase())
					)
					: true
			)
			.filter((cause: CampaignArrayOutput) =>
				selectedCategory
					? selectedCategory === 'money'
						? cause.donations?.money
						: cause.donations?.items || cause.donations?.voluntary
					: true
			)
		: [];


	const startIndex = (currentPage - 1) * pageSize;
	const pagedCauses = filteredCauses.slice(startIndex, startIndex + pageSize);

	console.log("pagedCauses", pagedCauses)
	console.log("filteredCauses", filteredCauses)
	console.log("causas", causes)
	return (
		<>
			<Container.Page>
				<Navbar />
				<SliderCampaign />
				<Container.Main>
					<Container.Title>Ajude a fazer o bem!</Container.Title>
					<Container.Header>
						<SearchBar searchTerm={searchTerm} onSearchTermChange={handleSearchTermChange} />
						<CategorieIcons setSelectedCategory={setSelectedCategory} />
						{filteredCauses.length > 0 && (
							<PaginationListCause
								totalCount={0}
								pageSize={pageSize}
								startIndex={startIndex}
								pagedCauses={pagedCauses}
								causes={causes}
								pageSizeOptions={pageSizeOptions}
								handlePageSizeChange={handlePageSizeChange}
								setCurrentPage={setCurrentPage}
								currentPage={currentPage}
							/>
						)}
					</Container.Header>
					<Container.Cards>
						{pagedCauses.filter((cause: CampaignArrayOutput) =>
							searchTerm
								? [cause.title?.toLowerCase(), cause.description?.toLowerCase()].some((field) =>
									field?.includes(searchTerm.toLowerCase())
								)
								: true
						).length > 0 ? (
							pagedCauses.map((cause: CampaignArrayOutput) => <Campaign key={cause.id} cause={cause} />)
						) : (
							<div>
								<p>
									Lamentamos, nenhuma campanha encontrada. Tente novamente com outro termo para busca.
								</p>
							</div>
						)}
					</Container.Cards>
				</Container.Main>
			</Container.Page>
			<Footer />
		</>
	);
};
