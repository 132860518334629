import styled from "styled-components";


const Container__Footer = styled.footer`
    display: flex;
    font-size: 15px;
    background: #467DC0;
    padding: 30px 35px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    align-self: flex-start;
    gap: 30px;
    @media (max-width: 480px) {
      padding: 30px 15px;
    }
`;
const Container__Img = styled.div`
    img {
        max-width: 200px;
        max-height: 200px;
    }
`;

const Container__Icons = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Container__Icon = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: transparent;
    color: white;
    border: solid 1px white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    &:hover {
      color: #FBB22F;
      border: solid 1px #FBB22F;
    };
    a {
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
`;

const Container__ContactItem = styled.div`
    margin-top: 8px;
    display: flex;
    align-items: center;
    color: #fbb22f;
    p {
        color: #FFF;
        font-size: 12px;
    }
`;

const Container__Left = styled.div`
display: flex;
flex-direction: column;
gap: 30px;
`;

const Container__Right = styled.div`
display: flex;
flex-direction: column;
color: #fbb22f;
`;

export const Container = {
	footer: Container__Footer,
	icons: Container__Icons,
	icon: Container__Icon,
	img: Container__Img,
  contactItem: Container__ContactItem,
  left: Container__Left,
  right: Container__Right
};
