import { UniqueCampaignOutput } from '../../../../interfaces/interfaces';
import { Container, Description } from './styles';
import { useMemo } from 'react';

interface Props {
  selectedCause: UniqueCampaignOutput;
}

export const DescriptionCampaign: React.FC<Props> = ({ selectedCause }) => {

  const handleAccumulatedValue = useMemo(() => {
    const accumulatedValue = selectedCause?.moneyDonationOptions?.accumulated;
    if (accumulatedValue) {
      return accumulatedValue / 100;
    } else {
      return 0;
    }
  }, [selectedCause?.moneyDonationOptions?.accumulated]);
  return (
    <Container.Box>
      <Container.Value>
        <Description.Subtitle>
          <span>Instituição</span>
        </Description.Subtitle>
        <Description.Text>{selectedCause?.institution?.name}</Description.Text>
      </Container.Value>
      <Container.Value>
        <Description.Subtitle>Meta:</Description.Subtitle>
        <h4>R$ {selectedCause?.moneyDonationOptions?.target?.toFixed(2) ?? '0'}</h4>
      </Container.Value>
      <Container.Value>
        <Description.Subtitle>Arrecadado:</Description.Subtitle>
        <h3>R$ {handleAccumulatedValue},00</h3>
      </Container.Value>
    </Container.Box>
  );
};
