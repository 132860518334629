import {
	createBrowserRouter,
	RouterProvider as ReactRouterDomProvider,
} from "react-router-dom";
import { Cause } from "../../pages/Campaign/Campaign";
import { Home } from "../../pages/Home";
import { ListCampaing } from "../../pages/ListCampaign";

export const RouterProvider = () => {
	return (
		<ReactRouterDomProvider router={routerToUser} />
	);
};

const routerToUser = createBrowserRouter([
	{
		path: "/",
		element: <Home />,
	},
	{
		path: "/campanhas",
		element: <ListCampaing />,
	},
	{
		path: "/campanhas/unique/:path",
		element: <Cause />,
	},
]);