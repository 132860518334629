
import { CauseUnit } from "./components/CauseUnit";
import Footer from "../../components/common/Footer";
import { Navbar } from "../../components/common/Navbar";
import { SliderCampaign } from "../../components/common/SliderCampaing";

export const Cause = () => {
	return (
		<>
			<Navbar />
			<SliderCampaign />
			<CauseUnit />
			<Footer />
		</>
	);
};
