import styled from "styled-components";

interface StyledBurgerProps {
  open: boolean;
  
}


const Container__Nav = styled.div<StyledBurgerProps>`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
    @media (max-width: 1024px){
        margin: 0;
        justify-content: flex-start;
        flex-direction: column;
        background:  white;
        position: fixed;
        transform: ${({ open }) => open ? "translateX(0)" : "translateX(100%)"};
        height: auto;
        width: 75%;
        height: 100vh;
        top: 0px;
        right: 0%;
        padding: 50px;
        transition: transform 0.3s ease-in-out; 
    }
`;

const Container__Right = styled.nav<StyledBurgerProps>`
    display: flex;
    color: black;
    cursor: pointer;
    ul {
      display: flex; 
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      gap: 30px;
    }
    li {
      color: #FFFFFF;
      padding: 2px 8px;
      border-radius: 2px;
      display: flex; 
      &:hover {
        color: #fbb22f; 
        background: #467dc0;
      }
      img {
        padding-right: 5px;
      }
    };


    @media (max-width: 1024px){
        margin: 20px 0;
        width: 100%;
        text-align: center;
        ul {
          flex-direction: column;
          gap: 40px;
        }
        li {
            color: black;
            font-size: 24px;
            padding-right: 0;
            &:hover {
            color: gray;
        };
    };
};
`;

export const Container = {
	Nav: Container__Nav,
	Right: Container__Right,
};

