
import { Link } from "react-router-dom";
import { Container, Text, Button } from "./styles";


export const BannerVideo = () => {

	return (
		<Container.Banner id="recompensas">
			<Container.Box>
				<Text.Title><h2>Programa de <span>recompensas</span></h2></Text.Title>
				<Container.Text>
					<iframe
						src="https://www.youtube.com/embed/pAUJfvXSTrA" title="YouTube video player"
						frameBorder="0"
						allow="accelerometer; autoplay; 
        clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						allowFullScreen></iframe>

					<Text.Desc>A partir de agora, sempre que participar de uma das centenas de ações
						propostas pela Gooders ou pelas nossas ONGs cadastradas, ou simplesmente fizer
						uma benfeitoria, você acumulará pontos
						que podem ser trocados por descontos e experiências em nossos parceiros.
						<Link to="/campanhas"><Button>Apoie uma causa!</Button></Link>
					</Text.Desc>

				</Container.Text>
			</Container.Box>


		</Container.Banner>
	);
};
