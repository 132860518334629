import styled from "styled-components";


const Container__Location = styled.div`
  width: 100%;
  display: flex;
  justify-content:flex-end;
  width: 100%;
  gap: 10px;
  color:#6B7786;
  align-items: center;
  span {
  font-size: 10px;
  line-height: 18px;
  }
`;

export const Container = {
	Location: Container__Location,
};

const Description__Title = styled.h2` 
  font-weight: 600;
  font-size: 28px;
  line-height: 44px;
  color: #4E545C;
  padding-bottom: 8px;
`;

const Description__SubTitle = styled.div` 
  font-weight: 500;
  line-height: 24px;
  color:#6B7786;
  p {
    color:#6B7786;
    padding-bottom: 8px;
  }
`;

export const Description = {
	Title: Description__Title,
	Subtitle: Description__SubTitle,
};