import styled from "styled-components";
import theme from "../../themes/theme";

const Container__Page = styled.div`
padding-bottom: 50px;
`;

const Container__Main = styled.div`
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
text-align: left;
padding-top: 20px;
`;


const Container__Header = styled.div`
display: grid;
grid-template-columns: 3fr 1fr;
align-items: center;
justify-content: space-between;
width: 90%;
@media (max-width:${theme.media.monitor}) {
  grid-template-columns: 3fr 1fr;
}
@media (max-width:${theme.media.monitorSmall}) {
  grid-template-columns: 3fr 1fr;
}
@media (max-width:${theme.media.laptop}) {
  grid-template-columns: 1fr;
}
`;

  const Container__Cards = styled.div`
  width: 90%;
    padding-top: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
    gap: 8px;
  `;

  const Container__Title = styled.h1`
  width: 80%;
  font-style: normal;
font-weight: 600;
font-size: 28px;
line-height: 32px;
color: #467DC0;
padding-bottom: 24px;
  `;

export const Container = {
	Page: Container__Page,
	Cards: Container__Cards,
	Header: Container__Header,
  Main: Container__Main, 
  Title: Container__Title,
};
