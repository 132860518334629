import { Container } from "./styles";
import doe_money from "../../../../assets/icones/doe_money.svg";
import doe_items from "../../../../assets/icones/doe_items.svg";
import doe_voluntary from "../../../../assets/icones/doe_voluntary.svg";

interface Props {
	setSelectedCategory: React.Dispatch<React.SetStateAction<string>>;
}

export const CategorieIcons: React.FC<Props> = ({ setSelectedCategory }) => {

	return (

		<Container.Categorie >
			<Container.Icon onClick={() => setSelectedCategory("money")}><img src={doe_money} alt="doe_money" /> Arrecadação</Container.Icon>
			<Container.Icon onClick={() => setSelectedCategory("items")}><img src={doe_items} alt="doe_items" /> Doação</Container.Icon>
			<Container.Icon onClick={() => setSelectedCategory("voluntary")}><img src={doe_voluntary} alt="doe_voluntary" /> Voluntariado</Container.Icon>
		</Container.Categorie>


	);
};
