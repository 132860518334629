import styled from "styled-components";


export const Container = styled.section`
    display: flex;
    font-size: 15px;
    background: #376399;
    color: white;
    align-items: center;
    padding: 10px 35px;
    justify-content: space-between;
    align-self: flex-start;
    gap: 20px;
    font-size: 12px;
    img {
        max-width: 200px;
        max-height: 200px;
    }
    @media (max-width: 480px) {
      flex-wrap: wrap;
      }
`;

export const SocialContainer = styled.div`
    display: flex;
`;
