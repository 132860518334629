import styled from "styled-components";

const Container__Box = styled.div`
    padding: 8px;
    max-width: 500px;
    font-family: 'Poppins', sans-serif;
`;
const Container__Cards = styled.div`
  max-width: 100%;
    color: black;
    border-radius: 8px;
    box-shadow: none;
    transition: all 300ms linear 0s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px;
    box-shadow: 0px 2px 10px rgba(73, 80, 87, 0.16);
`;

const Container__Image = styled.div`
margin: 0 6px;
  img {
  width: 240px;
  height: 160px;
  border-radius: 8px;
  object-fit: cover; }
`;

const Container__Text = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 0;
  gap: 8px;
  height: 140px;
  min-height: 10px;
  h3 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  }
  p {
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
  color: #6B7786;
  }
`;
const Container__Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Container__Button = styled.button`
font-family: 'Poppins', sans-serif;
  padding: 4px 28px;
  border: none;
  background: #467DC0;
  border-radius: 4px;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center; 
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  cursor: pointer;
  transition: all 300ms linear 0s; 
    &:hover{
      background: #6B97CD;
    }
`;

// const Container__Gooders = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center; 
//   color: #043560;
//   img {
//     width: 24px;
//     height: 24px;
//     padding-right: 8px;
// }
// p {
//       font-weight: 700;
//       font-size: 10px;
//       line-height: 18px;
//       padding-right: 3px;
//     }
//     span {
//       font-weight: 700;
//       font-size: 10px;
//       line-height: 18px;
//     }
// `;

const Container__Location = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  justify-content: flex-end;
  color: #6B7786;
  border-bottom: 2px solid #E4E7EB;
  align-items: center;
  padding-top: 8px;
  span {
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
  padding-bottom: 8px;
  }
  img {
    padding-bottom: 8px;
  }
`;

const Container__Icons = styled.div`
display: flex;
gap: 16px;
border-bottom: 2px solid #E4E7EB;
img {
  width: 20px;
}
`;

const Container__Content = styled.div`
width: 100%;
display: flex;
justify-content: space-between;

`;

const Container__Progress = styled.div`
padding: 8px 0;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  .barprogress {
    width: 250px;
    height: 8px;
    background: #E4E7EB;
  border-radius: 40px;
  }
`;


export const Container = {
	Box: Container__Box,
	Cards: Container__Cards,
	Image: Container__Image,
	Text: Container__Text,
	Button: Container__Button,
	Footer: Container__Footer,
	// Gooders: Container__Gooders,
  Icons: Container__Icons,
	Progress: Container__Progress,
	Location: Container__Location,
  Content: Container__Content
};

export const Line = styled.div``;
