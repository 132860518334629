import { BannerVideo } from "./components/BannerVideo";
import { Feature } from "../../components/common/Feature";
import Footer from "../../components/common/Footer";
import { Navbar } from "../../components/common/Navbar";
import { Slider } from "./components/Slider";
import Partner from "./components/Partner";
import { Develop } from "../../components/common/Develop";
import { ListCardsCause } from "../../components/common/ListCardsCause";



export const Home = () => {
	return (
		<>
			<Navbar />
			<Slider />
			<Feature />
			<Partner />
			<BannerVideo />
			<ListCardsCause />
			<Footer />
			<Develop />
		</>
	);
};
