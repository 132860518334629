import { Container, Text } from "./styles";

export const SliderCampaign = () => {
	return (
		<Container.Main>
			<Container.Text>
				<Text.Title>#FazerOBemRECOMPENSA!</Text.Title>
				
			</Container.Text>
		</Container.Main>
	);
};


