import { Container, Wrapper, Cards } from './styles';
import doe from "../../../assets/cards/doe.png";
import inspire from "../../../assets/cards/inspire.png";
import resgate from "../../../assets/cards/resgate.png";

export const Card = () => {
    return (
        <Container>
            <Wrapper>
                <Cards>
                    <img src={doe} alt="doe" />
                    <h4>Doe, Contribua</h4>
                    <p>Contribua com ações sociais credenciadas ou indique uma instituição.</p>
                </Cards>
                <Cards>
                    <img src={inspire} alt="inspire" />
                    <h4>Inspire, Receba</h4>
                    <p>Receba moedas Gooders por cada ação realizada ou instituições indicadas.</p>
                </Cards>
                <Cards>
                    <img src={resgate} alt="resgate" />
                    <h4>Resgate, Recompensas</h4>
                    <p>Troque moedas por descontos converta em dinheiro ou em créditos sociais.</p>
                </Cards>
            </Wrapper>
            
        
        </Container>
    )
}
