import { Container } from "./styles";
import { Link } from "react-router-dom";
import { LayoutModal } from "./component/LayoutModal";
import { useState } from "react";
import doe_money from "../../../assets/icones/doe_money.svg"
import doe_items from "../../../assets/icones/doe_items.svg"
import doe_voluntary from "../../../assets/icones/doe_voluntary.svg"
import location from "../../../assets/icones/location.svg"
import { CampaignArrayOutput } from "../../../interfaces/interfaces";

interface Props {
	cause: CampaignArrayOutput;
}


export const Campaign = ({ cause }: Props) => {

	const [openModal, setOpenModal] = useState(false);


	return (
		<Container.Box>
			<Container.Cards onClick={() => cause?.category}>
				<Link to={`/campanhas/unique/${cause?.id}`}>
					<Container.Image>
						<img src={cause?.image} alt="cause" />
					</Container.Image>
				</Link>

				<Container.Text>
					<h3>
						{cause?.title && cause?.title.length > 30
							? cause?.title.substring(0, 30) + '...'
							: cause?.title}
					</h3>
					<p>
						{cause?.summary && cause?.summary?.length > 220
							? cause?.summary.substring(0, 150) + '...'
							: cause?.summary}
					</p>
				</Container.Text>
				<Container.Content>
					<Container.Icons>
						{cause?.donations.money ? <img src={doe_money} alt="doe_money" /> : null}
						{cause?.donations.items ? <img src={doe_items} alt="doe_items" /> : null}
						{cause?.donations.voluntary ? <img src={doe_voluntary} alt="doe_voluntary" /> : null}
					</Container.Icons>
					<Container.Location>
						<img src={location} alt="cidade" />
						<span>{cause?.city} - </span>
						<span>{cause?.state}</span>
					</Container.Location>
				</Container.Content>
				<Container.Progress>
					{/* ligar ao backend */}
					{/* <span>0%</span>
          <div className="barprogress"></div> */}
				</Container.Progress>
				<Container.Footer>
					{/* <Container.Gooders>
							<img src={logo_coin} alt="gooders" />
							<p>2x</p>
							<span>Coins</span>
						</Container.Gooders> */}
					<Container.Button onClick={() => {

						setOpenModal(true);
						console.log('Botão clicado!')
						// console.log(selectedCause)
					}}>
						Apoiar Causa
					</Container.Button>
				</Container.Footer>
				<LayoutModal
					open={openModal}
					onClose={() => setOpenModal(false)}
					selectedCause={cause}
				/>
			</Container.Cards>

		</Container.Box>
	);
};
