import { Container } from "./styles";
import IonIcon from "@reacticons/ionicons";

interface SearchBarProps {
	searchTerm: string;
	onSearchTermChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const SearchBar: React.FC<SearchBarProps> = ({
	searchTerm,
	onSearchTermChange,
}) => {


	return (


		<Container.Search >
			<label htmlFor="search">
				<button type="submit"><IonIcon name="search-outline" /></button>
				<input 
					type="search" 
					placeholder="Encontre uma causa"
					value={searchTerm}
					onChange={onSearchTermChange} />
			</label>
		</Container.Search>


	);
};
