import styled from "styled-components";
import theme from "../../../../themes/theme";


const Container__Categorie = styled.div`
display: flex;
align-items: center;
justify-content: center;
gap: 32px;
color: #6B7786;
padding: 0 16px;
img{
  width: 22px;
}
@media (max-width:${theme.media.laptop}) {
  padding: 16px 0;
  justify-content: flex-start;
}
`;

const Container__Icon = styled.div`
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
font-size: 12px;
border-bottom: 1px solid transparent;
:hover {
  border-bottom: 1px solid #FBB22F;
}
`;


export const Container = {
	Categorie: Container__Categorie,
	Icon: Container__Icon,
};


