import { useEffect, useState } from "react";
import { Container } from "./styles";
import { useParams } from "react-router-dom";
import { LayoutModal } from "../../../../components/common/CardsCause/component/LayoutModal";
import share from "../../../../assets/icones/share.svg"
import { UniqueCampaignOutput } from "../../../../interfaces/interfaces";
import { axiosClient } from "../../../../components/config/axios";
import { ImageCampaign } from "../ImageCampaign";
import { HeaderCampaign } from "../HeaderCampaign";
import { DescriptionCampaign } from "../DescriptionCampaign";
import { BlueButton } from "../../../../components/common/Button";

export interface Props {
	cause: UniqueCampaignOutput;
}

export const CauseUnit: React.FC = () => {

	const [openModal, setOpenModal] = useState(false);
	const location = useParams();
	const [isLoading, setIsLoading] = useState(false);
	const [selectedCause, setSelectedCause] = useState<UniqueCampaignOutput>({} as UniqueCampaignOutput);


	useEffect(() => {
		const fetchCauses = async () => {
			try {
				const response = await axiosClient.get<{ data: UniqueCampaignOutput }>(`https://app.gooders.com.br/campaigns/unique/${location.path}`);
				const data = response.data;
				setSelectedCause(data.data);
				console.log("data.data", data.data)
			} catch (error) {
				console.error(error);
			}
			window.scrollTo(0, 0);
		};
		fetchCauses();
	}, []);
	console.log("selectedCause", selectedCause)
	console.log("selectedCause.image", selectedCause.image)

	const handleButtonClick = () => {
		setIsLoading(true);
		setSelectedCause(selectedCause);
		setOpenModal(true);
		setIsLoading(false);
	};

	const handleShare = () => {
		navigator.share({
			title: document.title,
			text: 'Gooders',
			url: window.location.href,
		});
	};


	return (
		<Container.main>
			{selectedCause?.images && selectedCause.images[0] && (
				<ImageCampaign srcOfImages={selectedCause.images.map(({ src }) => src) ?? []} />
			)}
			<Container.description>
				<HeaderCampaign selectedCause={selectedCause} />
				{selectedCause?.moneyDonationOptions && <DescriptionCampaign selectedCause={selectedCause} />}
				<BlueButton onClick={handleButtonClick} loading={isLoading}>
					Apoiar Causa
				</BlueButton>
				<Container.box>
					<Container.share onClick={handleShare}>
						<p>Compartilhe esta causa:</p>
						<span>
							<img src={share} alt="gooders" />
							clique aqui
						</span>
					</Container.share>
				</Container.box>
			</Container.description>
			<LayoutModal
				open={openModal}
				onClose={() => setOpenModal(false)}
				selectedCause={selectedCause}
			/>
		</Container.main>
	);
};
