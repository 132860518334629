import styled from "styled-components";


const Container__Feature = styled.section`
  padding: 0px 166px 120px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: center;
  @media (max-width: 768px) {
    padding: 0px 50px 30px;
      }
    `;

const Container__Box = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: center;
  @media (max-width: 1024px) {
      flex-direction: column;
      }
`;

const Container__Icon = styled.div`
  flex: 1;
  display: flex;
  justify-content: end;
  img {
    min-width: 100%;
    max-width: 100%;
    height: auto;
    
  }

`;
const Container__Text = styled.div`
  flex: 1;
  
`;

export const Container = {
	Feature: Container__Feature,
	Box: Container__Box,
	Icon: Container__Icon,
	Text: Container__Text,
};

const Text__Title = styled.h2`
    padding-top: 30px;
    color: #467DC0;
    font-weight: 700;
    font-size: 48px;
    line-height: 52px;
  span {
    text-decoration:  underline 3px solid #FBB22F;;
  }
`;

const Text__desc = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #4E545C;
  text-align: left;
`;

export const Text = {
	Title: Text__Title,
	Desc: Text__desc,
	
};