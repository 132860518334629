import IonIcon from "@reacticons/ionicons";
import logo_white from "../../../assets/logo/logo_white.svg";
import { Container } from "./styles";


const Footer: React.FC = ( ) => {
	return (
			<Container.footer >
				<Container.left>
			<Container.img>
				<img className="colorImg" src={logo_white} alt="logo"></img>
			</Container.img>
			<Container.icons >
				<Container.icon >
				<a href="https://www.facebook.com/GoodersBR/" target={"_blank"}>
					<IonIcon name="logo-facebook"></IonIcon>
				</a>
				</Container.icon>
				<Container.icon >
				<a href="https://www.instagram.com/goodersbr/" target={"_blank"}>
					<IonIcon name="logo-instagram"></IonIcon>
					</a>
				</Container.icon>
				<Container.icon >
				<a href="https://twitter.com/goodersofficial" target={"_blank"}>
					<IonIcon name="logo-twitter"></IonIcon>
					</a>
				</Container.icon>
				<Container.icon >
					<a href="https://www.linkedin.com/company/gooders/mycompany/" target={"_blank"}>
						<IonIcon name="logo-linkedin"></IonIcon>
					</a>
				</Container.icon>
				<Container.icon >
				<a href="https://www.youtube.com/@gooderssocialimpactbusines1549" target={"_blank"}>
					<IonIcon name="logo-youtube"></IonIcon>
					</a>
				</Container.icon>
			</Container.icons>
			</Container.left>
			<Container.right>
				<h5>Atendimento</h5>
			<Container.contactItem>
                    <IonIcon name="location-outline" style={{ marginRight: "10px" }} /> 
                    <p>Rua Campanário, 532 - Belo Horizonte/MG</p>
                </Container.contactItem>
                <Container.contactItem>
                    <IonIcon name="at-outline" style={{ marginRight: "10px" }} />
                    <p>contato@gooders.com.br</p>
										</Container.contactItem>
										<Container.contactItem>
                    <IonIcon name="document-outline" style={{ marginRight: "10px" }} />
                    <p>Cnpj: 29.443.372/0001-74</p>
										</Container.contactItem>
		</Container.right>
		</Container.footer>
	);
};

export default Footer;