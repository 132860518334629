import styled from "styled-components";

interface StyledBurgerProps {
  open: boolean;
}


export const StyledBurger = styled.div<StyledBurgerProps>`
    width: 2rem;
    height: 2rem;
    position: fixed;
    top: 15px;
    right: 55px;
    z-index: 20;
    display: none;
    @media (max-width: 1024px) {
        display: flex;
        padding-right: 15px;
        justify-content: space-around;
        flex-flow: column nowrap;
    }
    div {
    width: 2rem;
    height: 0.25rem;
    background-color: gray;
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
    &:nth-child(1) {
        transform: ${({ open }) => open ? "rotate(45deg)" : "rotate(0)"};
    }
    &:nth-child(2) {
        transform: ${({ open }) => open ? "translateX(100%)" : "translateX(0)"};
        opacity: ${({ open }) => open ? 0 : 1};
    }
    &:nth-child(3) {
        transform: ${({ open }) => open ? "rotate(-45deg)" : "rotate(0)"};
    }
    };
`;
