import { Container, Button, Input } from "./styles";
import { useState } from "react";
import { UniqueCampaignOutput } from "../../../../../../interfaces/interfaces";
import { useNavigate } from "react-router-dom";

interface Props {
  selectedCampaign?: UniqueCampaignOutput;
}

export const ModalDonation: React.FC<Props> = ({ selectedCampaign }) => {
  const [donation, setDonation] = useState("");
  const [quantity, setQuantity] = useState("");
  const [selectedItemAddress, setSelectedItemAddress] = useState("");
  const navigate = useNavigate();


  const handleDonationChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedItem = selectedCampaign?.items?.find(
      (item) => item.name === e.target.value
    );
    setDonation(e.target.value);
    if (selectedItem) {
      setSelectedItemAddress(selectedItem.address);
    }
  };

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuantity(e.target.value);
  };

  const handleDonateClick = () => {
    window.open('https://app.gooders.com.br/login', '_blank');
  };

  return (
    <>
      <Container.Input>
        <Container.Text>
          <Input.Donate>
            <label htmlFor="item" id="item"></label>
            <select
              name="item"
              id="item"
              value={donation}
              onChange={handleDonationChange}
              required
            >
              <option value="">Objeto de doação</option>
              {selectedCampaign &&
                selectedCampaign.items?.map((item) => (
                  <option key={item.id} value={item.name}>
                    {item.name}
                  </option>
                ))}
            </select>
          </Input.Donate>
          <Input.Hours>
            <label htmlFor="hour">
              <input
                type="text"
                placeholder="Quantidade"
                value={quantity}
                onChange={handleQuantityChange}
                required
              />
            </label>
          </Input.Hours>
          {selectedItemAddress && (
            <Container.Address>{selectedItemAddress}</Container.Address>
          )}
        </Container.Text>
      </Container.Input>
      <Button onClick={handleDonateClick}>Finalizar Doação</Button>
    </>
  );
};
