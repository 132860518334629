import { Container, Button, Input } from "./styles";
import { useState } from "react";
import { UniqueCampaignOutput } from "../../../../../../interfaces/interfaces";

interface Props {
  selectedCampaign?: UniqueCampaignOutput;
}

export const ModalPayment: React.FC<Props> = ({ selectedCampaign }) => {
  const [quantity, setQuantity] = useState("");


  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuantity(e.target.value);
  };

  const handleDonateClick = () => {
    window.open('https://app.gooders.com.br/login', '_blank');
  };

  return (
    <>
      <Container.Input>
        <Container.Text>
          <Input.Donate>
            <label htmlFor="valor">
              <input
                type="number"
                placeholder="Valor"
                value={quantity}
                onChange={handleQuantityChange}
                required
              />
            </label>
          </Input.Donate>
        </Container.Text>
      </Container.Input>
      <Button onClick={handleDonateClick}>Finalizar Doação</Button>
    </>
  );
};
