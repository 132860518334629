import styled from "styled-components";
import theme from "../../../../themes/theme";

const Container__Page = styled.main`
  background: #F5F5F5;
  min-height: 800px;
  color: #467dc0;
  padding: 0 52px 40px;
  @media (max-width:${theme.media.mobileSmall}) {
    padding: 0 10px;
  }
`;

const Container__Header = styled.div`
  height: 62px;
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  @media (max-width:${theme.media.mobileSmall}) {
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: auto;
  }
`;

const Container__Title = styled.h1`
	font-weight: 600;
  font-size: ${props => props.theme.fontSize.x};
`;

const Container__Main = styled.div`
  padding: 20px;
  width: 100%;  
  display: flex;
  gap: 16px;
  height: 100%;
  color: #467dc0;
  background: #FFF;
  border-radius: 8px;
  @media (max-width:${theme.media.monitorSmall}) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 95px 20px;
  } 
`;

const Container__Description = styled.div`
  width: 50%;
  min-height: 100%;
  padding: 16px 24px;
  box-shadow: 0px 2px 10px rgba(73, 80, 87, 0.16);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  @media (max-width:${theme.media.monitorSmall}){
      width: 100%;
    } 
`;

const Container__Box = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: space-between;
  @media (max-width:${theme.media.tablet}){
    flex-direction: column;
    align-items: flex-start;
  } 
`;

const Container__Share = styled.div`
  flex: 3;
  display: flex;
  gap: 16px;
  padding: 17px 0;
  font-weight: 500;
  cursor: pointer;
  span {
    border-bottom: 2px solid #6B97CD;;
    color: #6B97CD;
  img {
    padding-right: 8px;
  }
  }
  @media (max-width:${theme.media.mobileSmall}) {
    flex-direction: column;
    width: 100%;
  } 
`;

export const Container = {
	page: Container__Page,
	title: Container__Title,
	header: Container__Header,
	main: Container__Main,
	description: Container__Description,
	box: Container__Box,
	share: Container__Share,
};
