import styled from "styled-components";



const Container__Header = styled.header<{ isScrolled: boolean }>`
    text-transform: uppercase;
    position: fixed;
    width: 100vw;
    height: 80px;
    z-index: 999;
    background: linear-gradient(123.37deg, rgba(255, 255, 255, 0.4) 12.84%, rgba(255, 255, 255, 0.1) 97.57%);
    background: ${({ isScrolled }) => (isScrolled ? "#467DC0" : "linear-gradient(123.37deg, rgba(255, 255, 255, 0.4) 12.84%, rgba(255, 255, 255, 0.1) 97.57%)")};
    box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Container__Box = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    `;

const Container__Image = styled.img`
  padding: 10px;
  min-width: 150px;
`;

const Container__Nav = styled.div`
    flex: 2;
`;

export const Container = {
	Header: Container__Header,
	Box: Container__Box,
	Nav: Container__Nav,
	Image: Container__Image,
};