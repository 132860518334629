import styled from "styled-components";
import theme from "../../../../themes/theme";


const Container__Pagination = styled.div`
display: flex;
color: #6B7786;
flex-direction: row;
justify-content: flex-end;
gap: 8px;
align-items: center;
label {
  font-size: 14px;
}
select {
  border: none;
  outline: none;
  padding: 8px;
  color: #6B7786;
  font-size: 12px;
}
span {
  font-size: 14px;
}
@media (max-width:${theme.media.monitor}) {
  justify-content: flex-start;
}

`;

export const Container = {
	Pagination: Container__Pagination,
};
